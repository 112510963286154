import React, { useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { MEDIA_MIN_MEDIUM } from "../../constants"

const Cookie = styled.li`
  width: 100%;
  padding: 1rem 0;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
  cursor: pointer;
`

const Name = styled.p`
  font-weight: 700;
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  word-break: break-all;

  span {
    padding-right: 2rem;
  }

  i {
    padding-top: 0.6rem;
  }

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.8rem;

    i {
      padding-top: 0.4rem;
    }
  }
`

const Table = styled.div`
  display: ${(p) => (p.expanded ? "table" : "none")};
  width: 100%;
  background: ${(p) => p.theme.colors.white};
  padding: 1rem 2rem;
  margin: 3rem 0 3rem;
`

const Row = styled.div`
  padding: 1.5rem 0;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
  display: flex;
  flex-wrap: wrap;

  ${MEDIA_MIN_MEDIUM} {
    flex-wrap: nowrap;
  }

  &:last-child {
    border: none;
  }
`

const Label = styled.div`
  font-weight: 700;
  min-width: 15rem;
`

const Value = styled.div`
  width: 100%;

  ${MEDIA_MIN_MEDIUM} {
    width: auto;
  }
`

const CookieTable = ({ cookie }) => {
  const { t } = useTranslation("translations")
  const { name, purpose, type, category, domain, duration } = cookie
  const [expanded, setExpanded] = useState(false)

  return (
    <Cookie onClick={() => setExpanded(!expanded)}>
      <Name>
        <span>{name}</span>
        {expanded ? (
          <i className="fal fa-minus"></i>
        ) : (
          <i className="fal fa-plus"></i>
        )}
      </Name>
      <Table expanded={expanded}>
        <Row>
          <Label>{t("Purpose")}:</Label>
          <Value>{purpose}</Value>
        </Row>
        <Row>
          <Label>{t("Type")}:</Label>
          <Value>{type}</Value>
        </Row>
        <Row>
          <Label>{t("Category")}:</Label>
          <Value>{category}</Value>
        </Row>
        <Row>
          <Label>{t("Domain")}:</Label>
          <Value>{domain}</Value>
        </Row>
        <Row>
          <Label>{t("Duration")}:</Label>
          <Value>{duration}</Value>
        </Row>
      </Table>
    </Cookie>
  )
}

export default CookieTable
